@use 'styles/breakpoints';

.wrapper {
  padding-top: 56.25%; // 16:9
  position: relative;

  &Square {
    padding-top: 100%; // 1:1
  }
}

.image {
  object-fit: cover;

  &Square {
    object-fit: contain;
  }
}
