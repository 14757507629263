@use 'styles/typography';

.root {
  @include typography.default-s;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-decoration: none;
}

.time {
  @include typography.default-s-bold;
}
