@use 'styles/breakpoints';

.mobile {
  @include breakpoints.media(desktop-small) {
    display: none;
  }
}

.desktop {
  display: none;

  @include breakpoints.media(desktop-small) {
    display: block;
  }
}
