@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';

.root {
  @include typography.default-m;
}

.loading::after {
  content: '';
  display: inline-block;
  background-color: $yds-color-background-interactive;
  height: 1em;
  width: 12em;
  max-width: 100%;
}

.nowPlaying {
  display: block;
  @include typography.default-s-bold;
}
