@use 'styles/breakpoints';
@use 'styles/spacing';

.root {
  display: flex;
  flex-wrap: wrap;
}

.playControl {
  flex-grow: 1;
  flex-shrink: 0;

  @include breakpoints.media(mobile-landscape) {
    flex-grow: 0;
  }
}

.queueControl {
  flex-shrink: 0;
  margin-left: spacing.$spacing-24;

  @include breakpoints.media(mobile-landscape) {
    margin-left: spacing.$spacing-8;
  }
}
