@use 'styles/breakpoints';
@use 'styles/page-margins';
@use 'styles/spacing';

.containerWrapper {
  position: relative;
}

.contentWithNoHeader {
  @include page-margins.page-margins;
}
