@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

%loadingPlaceholder {
  content: '';
  display: inline-block;
  background-color: $yds-color-background-interactive;
  height: 1em;
  width: 15em;
  max-width: 100%;
}

@mixin loadingState {
  .isLoading &::after {
    @extend %loadingPlaceholder;
    @content;
  }
}

.imageHolder {
  display: block;
  position: relative;
  padding-top: 100%;
  background-color: $yds-color-background-interactive;
  margin-bottom: spacing.$spacing-24;
}

.timeAndLabels {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: spacing.$spacing-16;
  row-gap: spacing.$spacing-6;
  margin-bottom: spacing.$spacing-8;
}

.startAndEndTime {
  @include typography.default-m;

  flex: none;

  @include loadingState {
    width: 6em;
  }
}

.link {
  display: block;
  text-decoration: none;
}

.title {
  @include typography.default-l-bold;
  margin-top: 0;
  margin-bottom: spacing.$spacing-8;
  max-width: 43rem;

  @include breakpoints.media(tablet) {
    @include typography.default-xl-bold;
  }

  @include loadingState;
}

.description {
  @include typography.default-m;
  margin-bottom: spacing.$spacing-24;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-width: 43rem;

  @include loadingState;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.25rem;
  row-gap: 1rem;

  &:empty {
    @include loadingState {
      border-radius: 21px;
      height: 41px;
      width: 201px;
    }
  }
}

.song {
  margin-top: spacing.$spacing-24;
}
