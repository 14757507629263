@use 'styles/breakpoints';
@use 'styles/spacing';

.root {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  column-gap: spacing.$spacing-56;
  row-gap: spacing.$spacing-24;
  margin-top: spacing.$spacing-72;

  @include breakpoints.media(mobile-landscape) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include breakpoints.media(tablet-landscape) {
    justify-content: center;
  }
}
