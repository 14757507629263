@use 'styles/breakpoints';
@use 'styles/spacing';

.root {
  display: flex;
  flex-direction: column;
  row-gap: spacing.$spacing-32;
  padding-top: spacing.$spacing-24;
  padding-bottom: spacing.$spacing-24;

  @include breakpoints.media(mobile-landscape) {
    padding-top: spacing.$spacing-40;
    padding-bottom: spacing.$spacing-40;
  }

  @include breakpoints.media(desktop) {
    padding-top: spacing.$spacing-56;
  }
}

.channelNameAndRegion {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @include breakpoints.media(mobile-landscape) {
    justify-content: flex-start;
  }
}

.channelName {
  flex: none;
  margin: 0;
}

.regionPicker {
  align-self: center;
  flex: 0 1 auto;
  margin-left: spacing.$spacing-24;
}
