@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/spacing';
@use 'styles/typography';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: spacing.$spacing-8;
  row-gap: spacing.$spacing-4;
  color: $yds-color-gray-40;

  @include typography.default-s;

  &.lineBreak {
    flex-direction: column;
  }
}

.label {
  display: flex;
  align-items: center;
  white-space: pre-line;

  &WithLineSeparator {
    border-right: 1px solid rgba($yds-color-gray-40, 0.3);
    padding-right: 1rem;
    margin-right: 1rem;
  }

  &WithLineBreakSeparator {
    @include typography.default-m;
  }

  &WithSpaceSeparator {
    margin-right: spacing.$spacing-8;
  }

  &:last-child {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }
}

.bullet {
  margin: 0 0.5rem;
}
