@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  border-top: solid 1px $yds-color-border-disabled;
  padding-top: spacing.$spacing-16;
}

.title {
  @include typography.default-xs-bold;
  margin-top: 0;
  margin-bottom: spacing.$spacing-8;
}

.programs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    '. .'
    'guideLink guideLink';
  row-gap: spacing.$spacing-16;

  @include breakpoints.media(mobile-landscape) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: '. . guideLink';
  }
}

.program {
  .loading &::after {
    content: '';
    display: inline-block;
    background-color: $yds-color-background-interactive;
    height: 1em;
    width: 80%;
  }

  &:first-child {
    padding-right: spacing.$spacing-10;
  }

  &:nth-child(n + 2) {
    border-left: solid 1px $yds-color-border-disabled;
    padding-left: spacing.$spacing-10;

    @include breakpoints.media(mobile-landscape) {
      padding-right: spacing.$spacing-10;
    }
  }
}

.guideLink {
  grid-area: guideLink;

  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoints.media(mobile-landscape) {
    border-left: solid 1px $yds-color-border-disabled;
    padding-left: spacing.$spacing-10;
  }
}
