@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.header {
  margin-bottom: spacing.$spacing-16;
  padding-top: spacing.$spacing-24;
  @include breakpoints.media(tablet) {
    margin-bottom: spacing.$spacing-24;
  }
  @include breakpoints.media(desktop) {
    padding-top: spacing.$spacing-56;
  }

  &WithBackgroundImage {
    padding-top: 15rem;
    @include breakpoints.media(desktop) {
      padding-top: 16rem;
    }
  }
  &WithImage {
    padding-top: spacing.$spacing-24;

    @include breakpoints.media(desktop) {
      padding-top: spacing.$spacing-80;
    }
  }
  &WithLogo {
    padding-top: 8rem;
    @include breakpoints.media(desktop) {
      padding-top: 9rem;
    }
  }
}

.image {
  max-width: 618px;
  padding-bottom: spacing.$spacing-16;

  @include breakpoints.media(desktop-small) {
    max-width: none;
    padding-bottom: 0;
  }
}

.headerContent {
  max-width: 618px;

  &Podcast {
    @include breakpoints.media(desktop) {
      padding-top: 6.5rem;
    }
  }
}

.logo {
  margin-bottom: spacing.$spacing-16;
}

.preTitleControls {
  margin-bottom: spacing.$spacing-8;
}

.title {
  @include typography.default-xl-heavy;

  margin: 0;

  &Large {
    @include typography.default-4xl-heavy;

    @include breakpoints.media(tablet) {
      @include typography.default-5xl-heavy;
    }
  }
}

.playControls {
  margin-bottom: spacing.$spacing-16;
  margin-top: spacing.$spacing-16;
}

.description {
  @include typography.default-m;
  margin-top: 0;
  margin-bottom: spacing.$spacing-16;
  white-space: pre-line;

  &Minimized {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.subSection {
  margin-bottom: spacing.$spacing-16;
}

.subtitle {
  @include typography.default-m-bold;
  margin-top: spacing.$spacing-16;
  margin-bottom: spacing.$spacing-8;
}
