@use 'styles/breakpoints';

.desktop {
  display: none;
}

@include breakpoints.media(desktop-small) {
  .mobile {
    display: none;
  }

  .desktop {
    display: unset;
  }
}
