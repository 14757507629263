@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  display: flex;
  flex-wrap: wrap;
  gap: spacing.$spacing-8;
  justify-content: space-between;
  min-height: 120px;
  padding-top: spacing.$spacing-16;
  padding-bottom: spacing.$spacing-16;

  @include breakpoints.media(tablet) {
    min-height: 160px;
    padding-top: spacing.$spacing-24;
    padding-bottom: spacing.$spacing-24;
  }
}

.title {
  align-self: flex-end;
  flex-grow: 1;
  margin: 0;
  max-width: 618px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;

  @include typography.default-4xl-bold;

  .withLogo & {
    -webkit-line-clamp: 3;

    @include typography.default-xl-bold;

    @include breakpoints.media(tablet) {
      -webkit-line-clamp: 2;

      @include typography.default-5xl-bold;
    }
  }
}
