@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/typography';

.root {
  display: grid;
  grid-template-areas: 'icon text close';
  grid-template-columns: auto 1fr auto;
  border-radius: $yds-border-radius-large;

  &Text {
    padding: spacing.$spacing-12 0;
  }

  &Primary,
  &Secondary {
    padding: spacing.$spacing-12 spacing.$spacing-16;

    &.rootInfo {
      background-color: $yds-color-background-info-primary;
      color: $yds-color-text-negative;

      &.rootSecondary {
        background-color: $yds-color-background-info-secondary;
        color: $yds-color-text-default;
      }
    }
    &.rootSuccess {
      background-color: $yds-color-background-success;
      color: $yds-color-text-negative;
    }
    &.rootWarning {
      background-color: $yds-color-background-warning;
      color: $yds-color-text-negative;
    }
    &.rootError {
      background-color: $yds-color-background-error;
      color: $yds-color-text-negative;
    }
  }
}

.icon {
  grid-area: icon;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: spacing.$spacing-12;
}

.text {
  grid-area: text;
  margin-top: 1px;

  @include typography.default-s;
}

.title {
  display: inline;

  @include typography.default-s-bold;
}

.subtitle {
  display: inline;
}

.close {
  grid-area: close;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: inherit;
  margin: 0;
  margin-left: spacing.$spacing-12;
  padding: 0;
}

.closeIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}
