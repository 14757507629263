@use 'styles/breakpoints';
@use 'styles/spacing';

/**
Used to sync top and bottom margins between View and Guide components
Not used when the view has a hero
**/
@mixin page-margins {
  // Same spacings as between List components

  margin-top: spacing.$spacing-24;
  margin-bottom: spacing.$spacing-24;

  @include breakpoints.media(tablet) {
    margin-top: spacing.$spacing-32;
  }

  @include breakpoints.media(desktop-small) {
    margin-top: spacing.$spacing-40;
  }
}
