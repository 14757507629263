@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  margin-bottom: spacing.$spacing-24;

  @include breakpoints.media(tablet) {
    margin-bottom: spacing.$spacing-32;
  }
  @include breakpoints.media(desktop-small) {
    margin-bottom: spacing.$spacing-40;
  }
}

.imageContainer {
  position: relative;
  padding-top: 56.25%; // Aspect ratio 16:9
}

.image {
  object-fit: contain;
  object-position: center;
}

.textContentWrapper {
  display: flex;
  margin-top: spacing.$spacing-8;

  @include breakpoints.media(mobile-landscape) {
    margin-top: 0;
    min-height: 100%;
  }

  @include breakpoints.media(desktop-xl) {
    justify-content: center;
  }
}

.textContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
}

.title {
  margin: 0;

  @include typography.default-2xl-heavy;

  @include breakpoints.media(mobile-landscape) {
    @include typography.default-l-heavy;
  }

  @include breakpoints.media(tablet) {
    @include typography.default-2xl-heavy;
  }

  @include breakpoints.media(desktop) {
    @include typography.default-5xl-heavy;
  }

  @include breakpoints.media(desktop-xl) {
    @include typography.default-7xl-heavy;
  }
}

.description {
  margin: 0;
  margin-top: spacing.$spacing-8;
  @include typography.default-s;

  @include breakpoints.media(desktop) {
    @include typography.default-l;
  }
  @include breakpoints.media(desktop-xl) {
    @include typography.default-xl;
  }
}

.controls {
  margin-top: spacing.$spacing-16;

  @include breakpoints.media(tablet-landscape) {
    margin-top: spacing.$spacing-32;
  }

  @include breakpoints.media(desktop) {
    margin-top: spacing.$spacing-40;
  }
}
