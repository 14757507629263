@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.tabListScroller {
  overflow: auto;

  // +/-2px hack is to prevent "overflow: auto" from clipping off focus outlines
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: -2px;
  margin-bottom: calc(spacing.$spacing-16 - 2px);

  @include breakpoints.media(tablet) {
    margin-bottom: calc(spacing.$spacing-24 - 2px);
  }
}

.tabList {
  display: flex;
  margin: 0;
  padding-left: 5%;

  &::after {
    content: '';
    display: block;
    flex: none;
    width: 5vw;
  }
}

.tab {
  font: inherit;
  @include typography.default-xl-bold;

  background: none;
  border: none;
  border-bottom: 4px solid transparent;
  color: $yds-color-action-primary;
  cursor: pointer;
  display: block;
  flex: none;
  margin: 0;
  margin-right: spacing.$spacing-24;
  min-height: 1em;
  outline-offset: 0;
  padding: 0;
  padding-bottom: spacing.$spacing-8;
  text-align: inherit;
  user-select: none;

  &:active,
  &:hover {
    opacity: 0.8;
  }
  &:last-child {
    margin-right: 0;
  }
  &[data-state='active'] {
    border-bottom-color: $yds-color-feedback-highlight;
  }
}
