@use 'styles/breakpoints';
@use 'styles/spacing';

.image {
  height: auto;
  max-width: 100%;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
}

.forGenericHeader {
  width: 223px;

  @include breakpoints.media(tablet) {
    width: 270px;
  }
}

.forPackageHeader {
  width: 155px;

  @include breakpoints.media(tablet) {
    width: 197px;
  }
}
